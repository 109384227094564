import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import BasicTable from "components/basicTable";
import Youtube from "components/youtube";
import { Link, graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "storage"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/hdd-ssd.jpg"}) { ...eyecatchImg },
    storageguide: file(relativePath: { eq: "storage/storage-guide.png"}) { ...normalImg },
    alternative: file(relativePath: { eq: "storage/alternative.png"}) { ...normalImg },
    storagekind: file(relativePath: { eq: "storage/storage-kind.png"}) { ...normalImg },
    harddiskdrive: file(relativePath: { eq: "storage/harddiskdrive.jpg"}) { ...normalImg },
    ssd: file(relativePath: { eq: "storage/ssd.jpg"}) { ...normalImg },
    pattern: file(relativePath: { eq: "storage/pattern.png"}) { ...normalImg },
    optane_desc: file(relativePath: { eq: "storage/optane_desc.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`HDD(ハードディスク)/SSDの選び方`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="HDD/SSDの選び方" mdxType="Image" />
    <p>{`HDD(Hard Disk Drive/ハードディスク)及びSSD(Solid State Drive)は補助記憶装置やストレージとも呼ばれ、ブラウザやオフィスソフトなどのアプリケーションや、動画や写真などのファイルを保存するためのスペースとして使われる**。`}</p>
    <p>{`容量を決めれば良いだけと思いがちだが、`}<strong parentName="p">{`ストレージの種類によりOSやアプリケーションの起動速度にも大きく関係してくる`}</strong>{`。それぞれの長所を活かしてHDDとSSDを組み合わせて使うことも多い。`}</p>
    <p>{`本記事では用途別に必要なストレージの容量及びHDD/SSDの種類と特徴を解説し、最適なストレージを選択できることを目指す。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "容量はどれぐらい必要？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%B9%E9%87%8F%E3%81%AF%E3%81%A9%E3%82%8C%E3%81%90%E3%82%89%E3%81%84%E5%BF%85%E8%A6%81%EF%BC%9F",
        "aria-label": "容量はどれぐらい必要？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`容量はどれぐらい必要？`}</h2>
    <div className="b-m-c">
      <Image {...props} name="storageguide" alt="ストレージ容量の目安" mdxType="Image" />
    </div>
    <p>{`512GB、1TBなどパソコンのスペックには記載があるが、容量の感覚を掴んでおくことは重要である。上図はざっくりとした目安を示したものである。`}</p>
    <p>{`動画や写真は画質を落とせば容量は少なくて済むため必ずしも正確ではないものの、目安としては十分使えるだろう。これを元に容量はどれぐらいがおすすめかを説明していく。`}</p>
    <SmallBox type="word" text="補足：単位の解説" mdxType="SmallBox">
パソコンの容量はバイトという単位で表される。パソコン上の動画・写真など全てのデータは２進数の0と1(これをビットと呼ぶ)で表現されるが、ビットが8つ集まったものをバイト(byte)と呼ぶ。以下単位<br /><br />
Byte(バイト)・・・0と1の羅列が8つ集まったもの<br />
KB(キロバイト)・・・1000バイト。テキスト1000字で約1KB<br />
MB(メガバイト)・・・1000KB、音楽や高画質な写真は5MB程度<br />
GB(ギガバイト)・・・1000MB、5分の動画、Word/Excelなどのアプリケーション<br />
TB(テラバイト)・・・1000GB、4K高画質動画20時間分、テレビシリーズ10本録画分<br />
    </SmallBox>
    <h3 {...{
      "id": "地デジ番組を多く録画するなら1TBは必要",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%9C%B0%E3%83%87%E3%82%B8%E7%95%AA%E7%B5%84%E3%82%92%E5%A4%9A%E3%81%8F%E9%8C%B2%E7%94%BB%E3%81%99%E3%82%8B%E3%81%AA%E3%82%891TB%E3%81%AF%E5%BF%85%E8%A6%81",
        "aria-label": "地デジ番組を多く録画するなら1TBは必要 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`地デジ番組を多く録画するなら1TBは必要`}</h3>
    <p>{`1時間の番組をある程度高画質で録画しようとしたら大体４GBくらい必要となる、地デジをそのままの画質で録画しようとすると１時間あたり8GB程度の容量を使ってしまう。この場合`}<strong parentName="p">{`1TBでは約120時間録画できる`}</strong>{`ことになるが、ドラマでいうとシリーズ10本といったところである。`}</p>
    <p>{`画質を落とせば３倍の360時間程度録画が可能であるが、意識せず保存していくと気づけば容量がなくなってしまうため、動画の保存をたくさんしたいと考えている人はできる限り大きなストレージ容量を確保しておくことをお勧めする。`}</p>
    <p>{`最低1TB(1000GB)、デスクトップパソコンであるなら4TBのHDDが安価に積めるため、容量を気にしたくない人は積んでおくと良い。`}</p>
    <h3 {...{
      "id": "4K動画は1TBで20時間",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#4K%E5%8B%95%E7%94%BB%E3%81%AF1TB%E3%81%A720%E6%99%82%E9%96%93",
        "aria-label": "4K動画は1TBで20時間 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4K動画は1TBで20時間`}</h3>
    <p>{`番組の録画ではなく、撮影した動画を高画質で保存しておきたい場合にも多くの容量が必要となる。特に4K動画でクオリティの高いものは`}<strong parentName="p">{`1時間の映像で50GB程度`}</strong>{`使ってしまう。`}</p>
    <p>{`子どもの行事の度に長時間高画質で動画を撮り保存しているような場合は十分な容量が必要である。一方でFullHD動画で撮るならば最低でも4K動画の４倍は長く撮ることができる。`}</p>
    <h3 {...{
      "id": "写真音楽は動画と比べると無視できるレベル",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%86%99%E7%9C%9F%E9%9F%B3%E6%A5%BD%E3%81%AF%E5%8B%95%E7%94%BB%E3%81%A8%E6%AF%94%E3%81%B9%E3%82%8B%E3%81%A8%E7%84%A1%E8%A6%96%E3%81%A7%E3%81%8D%E3%82%8B%E3%83%AC%E3%83%99%E3%83%AB",
        "aria-label": "写真音楽は動画と比べると無視できるレベル permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`写真/音楽は動画と比べると無視できるレベル`}</h3>
    <p>{`写真に関してはあまり撮り過ぎを意識する必要はなさそうである。スマホだと写真が容量を圧迫して稀に問題となるが、基本的に容量がスマホと比べて大きいパソコンではあまり問題とはならない。`}</p>
    <p>{`1枚あたり5MBだと高画質な写真となるが、その場合でも20万枚保存しないと1TBには到達しない。動画と比べると容量は必要が無いと言える。`}</p>
    <p>{`また同様に音楽CDもアルバムで100MB程度なので、100枚のアルバムを取り込んでもせいぜい10GBにしかならない。写真よりも小粒である。`}</p>
    <h3 {...{
      "id": "大型のゲームは50GB超で地味に圧迫",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%A7%E5%9E%8B%E3%81%AE%E3%82%B2%E3%83%BC%E3%83%A0%E3%81%AF50GB%E8%B6%85%E3%81%A7%E5%9C%B0%E5%91%B3%E3%81%AB%E5%9C%A7%E8%BF%AB",
        "aria-label": "大型のゲームは50GB超で地味に圧迫 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`大型のゲームは50GB超で地味に圧迫`}</h3>
    <p>{`パソコンのゲームソフトを何本もダウンロードする人は注意をした方が良い。`}<strong parentName="p">{`大型のゲームはおおよそ50GB以上`}</strong>{`、中には100GBを超えるものもあり、プレイするゲームが増えるにつれ容量がどんどんなくなってしまう。`}</p>
    <p>{`大抵のゲームはセーブデータをゲームサーバー側に保存しておいてくれるし、ゲームのアンインストールをした場合でもセーブデータはパソコンの別の場所に保存されている場合が多い。`}</p>
    <p>{`よってデータが消える心配は無く、容量が少ない場合には、プレイしなくなったゲームを小まめに消すことをおすすめする。`}</p>
    <h3 {...{
      "id": "OS含め初期アプリが20GB程度あったりする",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#OS%E5%90%AB%E3%82%81%E5%88%9D%E6%9C%9F%E3%82%A2%E3%83%97%E3%83%AA%E3%81%8C20GB%E7%A8%8B%E5%BA%A6%E3%81%82%E3%81%A3%E3%81%9F%E3%82%8A%E3%81%99%E3%82%8B",
        "aria-label": "OS含め初期アプリが20GB程度あったりする permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OS含め初期アプリが20GB程度あったりする`}</h3>
    <p>{`128GBのパソコンを買ってきて、パソコンの残り容量を調べると100GB程度しか残っていなかったりする。これはWindowsOS自体や各メーカーの初期ソフトが入っているためである。OS自体の容量は10GBは超え、Word、Excel、ウイルスバスターなど1GB程度あるアプリがチリに積もり、それなりの容量となっている。`}</p>
    <p>{`またブラウザが2度目のアクセスに備えて、一度アクセスしたサイトをストレージに保存しておくキャッシュ機能が自動で動作し、じわじわと容量を圧迫される。`}</p>
    <p>{`このため特に容量の少ないパソコンを買おうとしている場合は`}<strong parentName="p">{`実質的に使える容量としてマイナス30GB-40GB程度をして考えるのが良い`}</strong>{`。`}</p>
    <h3 {...{
      "id": "必要なストレージの容量は？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%BF%85%E8%A6%81%E3%81%AA%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8%E3%81%AE%E5%AE%B9%E9%87%8F%E3%81%AF%EF%BC%9F",
        "aria-label": "必要なストレージの容量は？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`必要なストレージの容量は？`}</h3>
    <p>{`結論として、`}<strong parentName="p">{`動画を多く保存する人は1TB以上`}</strong>{`、動画の撮影が趣味で高画質で多くの撮影がしたい場合や、テレビの録画を増やしていきたい人は無制限に必要な容量が増えていく可能性もあるので、先を見越して4TB程度積んでも良いと思われる。`}</p>
    <p>{`次に動画の保存はそれほどしそうにないけれども、`}<strong parentName="p">{`ゲームをする人は512GB`}</strong>{`はあると好ましい。１本50GB超のゲームもあるのでコアゲーマーならばより多く搭載すると良いだろう。`}</p>
    <p>{`最後にそれ以外で、`}<strong parentName="p">{`動画もゲームもほとんどしない場合でも256GB`}</strong>{`あると安心である。128GBではOS自体や初期アプリで容量が想定以上に圧迫され、何かの拍子に足りなくなることは十分に想定される。`}</p>
    <p>{`特にあまり詳しくない人は、映像を編集していたらゴミのファイルが溜まり20GB容量を使っていたみたいなことが容易に起こる。`}</p>
    <h2 {...{
      "id": "容量を抑える、減らす・増やすポイント",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%B9%E9%87%8F%E3%82%92%E6%8A%91%E3%81%88%E3%82%8B%E3%80%81%E6%B8%9B%E3%82%89%E3%81%99%E3%83%BB%E5%A2%97%E3%82%84%E3%81%99%E3%83%9D%E3%82%A4%E3%83%B3%E3%83%88",
        "aria-label": "容量を抑える、減らす・増やすポイント permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`容量を抑える、減らす・増やすポイント`}</h2>
    <Image {...props} name="alternative" alt="ストレージ" mdxType="Image" />
    <p>{`ストレージはパソコン本体だけではなく、外付けで対応する、あるいはインターネット経由でクラウドに保存するという手段もある。`}</p>
    <p>{`これを前提として考えればパソコン購入時に本体に組み込まれるストレージ容量をある程度節約できる可能性がある。`}</p>
    <h3 {...{
      "id": "外付けHDDSSDを使う",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%A4%96%E4%BB%98%E3%81%91HDDSSD%E3%82%92%E4%BD%BF%E3%81%86",
        "aria-label": "外付けHDDSSDを使う permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`外付けHDD/SSDを使う`}</h3>
    <p>{`USB経由で外付けのHDDまたはSSDを使い、あまり使わないファイルをしまっておく、あるいは常に挿しっぱなしにしておくのが良い。物によっては１万円を切る価格で4TBの外付けHDDを購入することができるためコストパフォーマンスも高い。`}</p>
    <p>{`少量のバックアップ程度ならば64GB程度のUSBメモリを購入して、それをバックアップ用としておくのもありであるが、外付けHDDが最も割安である。`}</p>
    <h3 {...{
      "id": "動画や写真をクラウドで管理する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8B%95%E7%94%BB%E3%82%84%E5%86%99%E7%9C%9F%E3%82%92%E3%82%AF%E3%83%A9%E3%82%A6%E3%83%89%E3%81%A7%E7%AE%A1%E7%90%86%E3%81%99%E3%82%8B",
        "aria-label": "動画や写真をクラウドで管理する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画や写真をクラウドで管理する`}</h3>
    <p>{`個人の写真や動画を`}<a parentName="p" {...{
        "href": "https://photos.google.com/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Google Photo`}</a>{`で管理し、ファイルデータを`}<a parentName="p" {...{
        "href": "https://www.dropbox.com/individual",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`DropBox`}</a>{`や`}<a parentName="p" {...{
        "href": "https://onedrive.live.com/",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`One Drive`}</a>{`といったWebストレージサービスで管理するのもおすすめである。1TBで約1000円程度の費用がかかるが、これらのサービスを使うことにより、`}<strong parentName="p">{`ストレージが壊れてデータを失う心配がなくなる`}</strong>{`。`}</p>
    <p>{`ただし、4Kなど高画質な動画は無料アップロードを規制されるなど制限があるため、品質にこだわりがある人はオリジナルを手元に残しておくことになる。`}</p>
    <p>{`また、インターネット越しにファイルを保存するため、アップロードとダウンロードには時間がかかりり、外付けHDDのように気軽にはできないことも注意点である。`}</p>
    <p>{`クラウドのストレージには無料枠があるため、使い勝手は事前に確認しておくと良いだろう。`}</p>
    <h2 {...{
      "id": "HDDとSSD。ストレージの種類と特徴を理解する",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#HDD%E3%81%A8SSD%E3%80%82%E3%82%B9%E3%83%88%E3%83%AC%E3%83%BC%E3%82%B8%E3%81%AE%E7%A8%AE%E9%A1%9E%E3%81%A8%E7%89%B9%E5%BE%B4%E3%82%92%E7%90%86%E8%A7%A3%E3%81%99%E3%82%8B",
        "aria-label": "HDDとSSD。ストレージの種類と特徴を理解する permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDとSSD。ストレージの種類と特徴を理解する`}</h2>
    <Image {...props} name="storagekind" alt="ストレージ" mdxType="Image" />
    <p>{`容量について要点を抑えたならば次にストレージの種類や組み合わせについて検討する必要がある。大分類のSSDとHDDを構造から説明して特徴を把握した上で、SSD/HDDごとの更に細かい種類(HDD/SSHD/AHCI SSD/NVMe SSD)を説明していく。`}</p>
    <h3 {...{
      "id": "SSDはHDDと比べ速度・耐衝撃性・静音性・消費電力で優れる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%AFHDD%E3%81%A8%E6%AF%94%E3%81%B9%E9%80%9F%E5%BA%A6%E3%83%BB%E8%80%90%E8%A1%9D%E6%92%83%E6%80%A7%E3%83%BB%E9%9D%99%E9%9F%B3%E6%80%A7%E3%83%BB%E6%B6%88%E8%B2%BB%E9%9B%BB%E5%8A%9B%E3%81%A7%E5%84%AA%E3%82%8C%E3%82%8B",
        "aria-label": "SSDはHDDと比べ速度・耐衝撃性・静音性・消費電力で優れる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDはHDDと比べ速度・耐衝撃性・静音性・消費電力で優れる`}</h3>
    <p>{`HDDはHard Disk Driveの頭文字であることから想像できるかもしれないが、下記のような構造をしている。プラッターと呼ばれる円盤が高速で回転し、磁気ヘッドが上下に動くことで0、１の情報を円板状に記憶していく。円盤上には磁性体の物質があり、磁気ヘッドが磁気を与えることで記録が可能である。`}</p>
    <Image {...props} name="harddiskdrive" alt="ハードディスクドライブ" mdxType="Image" />
    <p>{`一方でSSDはSolid State Driveと呼ばれ、内部にNAND型フラッシュメモリーを持ち、電荷の状態を操作して記憶を行う。USBメモリーと同じ記憶方式である。磁気ヘッドや円盤を持たず、下記のような見た目である。`}</p>
    <Image {...props} name="ssd" alt="SSDメモリ" mdxType="Image" />
    <p>{`HDD/SSDそれぞれの構造から推測できるかもしれないが、`}<strong parentName="p">{`SSDは物理的な制約を受けない分高速で、耐衝撃性に優れ、静音性が高い`}</strong>{`。`}</p>
    <p>{`HDDは磁気ヘッドが衝撃で故障しやすく、データの読み込みが物理的制約によりSSDほど高くはない。また、円盤を回転させるため消費電力も大きくなりがちである。`}</p>
    <p>{`ノートパソコンをもって満員電車に乗り込むような人はリスクが大きいため、SSDを搭載するメリットが大きいといえる。`}</p>
    <h3 {...{
      "id": "HDDは回転数が多いと高速",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#HDD%E3%81%AF%E5%9B%9E%E8%BB%A2%E6%95%B0%E3%81%8C%E5%A4%9A%E3%81%84%E3%81%A8%E9%AB%98%E9%80%9F",
        "aria-label": "HDDは回転数が多いと高速 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDは回転数が多いと高速`}</h3>
    <p>{`HDDは円盤状にある磁気ヘッドによりデータを記録することは先ほど述べたが、`}<strong parentName="p">{`単位面積当たりに書き込めるデータ量(記録密度)が同じであるとすると、回転が速いほど多くのデータを読み書きすることができる`}</strong>{`。`}</p>
    <p>{`回転数はrpm(revolution per minute)で表され、一分間に円盤(プラッタ)が回転する数である。毎分5400回転(5400rpm)、7200回転(7200rpm)の製品が発売されている。`}</p>
    <p>{`ただ円盤の枚数や精細さはHDDにより異なるため、回転数だけでは性能は判断できない。たとえば5400rpmで円盤が4枚の製品は7200rpmで円盤が3枚の製品と変わらないといった具合である。`}</p>
    <p>{`円盤枚数や精細さについては製品スペックとして記載がないためどのHDDが高速であるかは一概には判断できないが、`}<strong parentName="p">{`回転数は参考値としては活用できる`}</strong>{`だろう。`}</p>
    <h3 {...{
      "id": "SSHDの速度はHDDとSSDの中間",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSHD%E3%81%AE%E9%80%9F%E5%BA%A6%E3%81%AFHDD%E3%81%A8SSD%E3%81%AE%E4%B8%AD%E9%96%93",
        "aria-label": "SSHDの速度はHDDとSSDの中間 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSHDの速度はHDDとSSDの中間`}</h3>
    <p>{`あまり普及はしていないが、SSDとHDDの中間の速度を持つSSHDという製品もある。`}</p>
    <p>{`これは`}<strong parentName="p">{`HDDの中にSSDの領域を少量確保し、頻繁にアクセスされるデータにSSDを割り当ててることで速度の改善を図った製品`}</strong>{`である。`}<strong parentName="p">{`SSDというよりはHDDの一種`}</strong>{`である。`}</p>
    <p>{`SSDは価格で手が届かないけれどHDDよりは高速化させたいというニーズに対応している。`}</p>
    <p>{`次節でOptaneメモリーについて解説するが、これも頻繁にアクセスされるデータに対して高速なOptaneメモリーを割り当てることで高速化を狙ったものであり、考え方としてはSSHDと同様である。`}</p>
    <h3 {...{
      "id": "SSDはNVMeとAHCI方式がありNVMeがより高速",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#SSD%E3%81%AFNVMe%E3%81%A8AHCI%E6%96%B9%E5%BC%8F%E3%81%8C%E3%81%82%E3%82%8ANVMe%E3%81%8C%E3%82%88%E3%82%8A%E9%AB%98%E9%80%9F",
        "aria-label": "SSDはNVMeとAHCI方式がありNVMeがより高速 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`SSDはNVMeとAHCI方式がありNVMeがより高速`}</h3>
    <p>{`SSDはデータ伝送方法の違いからAHCI SSDとNVMe SSDの２種類があり。2.5インチSSDと呼ばれるハードディスクと同じ形をしたものがAHCI SSDで、スティックタイプの形のものが一般的にはNVMe SSDである。`}</p>
    <p>{`NVMeの場合は製品に必ずNVMeの記述があるため、記述があればNVMe、無ければAHCI方式と思ってもらっても良い。`}</p>
    <p>{`NVMeの方が高速であるが、Windowsの起動速度はAHCIとNVMeでほとんど差異がないなど得手不得手もあり、`}<strong parentName="p">{`動画編集や大容量ファイルのコピー作業が多い人にとってNVMeは効果を発揮しやすい`}</strong>{`。`}</p>
    <p>{`一方で`}<strong parentName="p">{`オフィスワークが中心の人は体感的な速度向上はあまり見込めない`}</strong>{`であろう。`}</p>
    <p>{`より詳しくは下記の記事に任せることとする。`}</p>
    <SmallBox type="link" mdxType="SmallBox">
  <Link to="/storage/ssd_nvme_ahci_m2_pcie_sata/" mdxType="Link">NVMe、AHCI、M.2など良く分からない人へ向けたSSDの選び方</Link>
    </SmallBox>
    <h3 {...{
      "id": "【速度比較】NVMe--ACHI--SSHD--HDD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%80%90%E9%80%9F%E5%BA%A6%E6%AF%94%E8%BC%83%E3%80%91NVMe--ACHI--SSHD--HDD",
        "aria-label": "【速度比較】NVMe  ACHI  SSHD  HDD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`【速度比較】NVMe > ACHI > SSHD > HDD`}</h3>
    <p>{`この節の終わりとして、実際の速度比較をしている動画を２点ほど紹介する。`}</p>
    <p>{`最初は5400rpm, 7200rpmのHDDとAHCI SSDを比較した動画。`}</p>
    <Youtube mdxType="Youtube">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/FwzZ_Ss5veg" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </Youtube>
    <p>{`これによると起動速度はSSDが23秒、HDD 7200rpmが65秒、HDD 5400rpmが75秒であった。また、あるオフィスのアプリケーションの起動時間はSSDが1.5秒、HDD 7200rpmが30秒、HDD 5400rpmが70秒であった。`}</p>
    <p>{`この動画は一例であるが、一般的に`}<strong parentName="p">{`SSDはHDDの半分程度の時間でアプリケーションやパソコンを起動できる`}</strong>{`。`}</p>
    <p>{`なお、アプリケーションの起動については正直ここまで差がつくことはほぼ無く、SSDが1.5秒だとするとHDDは2.5秒ぐらいの感覚である。このあたりはアプリ自体の特徴や使用状況によって変わってくる。`}</p>
    <p>{`次にゲームのロード時間を検証した動画である。`}</p>
    <Youtube mdxType="Youtube">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/2AYextvB9l4" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </Youtube>
    <p>{`動画の内容を表にまとめると次のようになる。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>ゲームタイトル</th>
      <th>NVMe SSD</th>
      <th>AHCI SSD</th>
      <th>SSHD</th>
      <th>HDD</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Shadow of the Tomb Raider</td>
      <td>12.46秒</td>
      <td>14.34秒</td>
      <td>31.46秒</td>
      <td>35.13秒</td>
    </tr>
    <tr>
      <td>Far Cry 5</td>
      <td>9.31秒</td>
      <td>10.20秒</td>
      <td>24.42秒</td>
      <td>28.52秒</td>
    </tr>
    <tr>
      <td>Assassin's Creed Odyssey</td>
      <td>27.15秒</td>
      <td>29.10秒</td>
      <td>50.39秒</td>
      <td>52.46秒</td>
    </tr>
    <tr>
      <td>The Witcher 3</td>
      <td>5.34秒</td>
      <td>5.34秒</td>
      <td>22.25秒</td>
      <td>22.34秒</td>
    </tr>
    <tr>
      <td>Kingdom Come Deliverance</td>
      <td>5.18秒</td>
      <td>5.35秒</td>
      <td>6.27秒</td>
      <td>16.18秒</td>
    </tr>
    <tr>
      <td>Battlefield 5</td>
      <td>69.25秒</td>
      <td>80.29秒</td>
      <td>83.09秒</td>
      <td>93.33秒</td>
    </tr>
    <tr>
      <td>Ghost Recon Wildlands</td>
      <td>11.16秒</td>
      <td>12.13秒</td>
      <td>22.05秒</td>
      <td>28.44秒</td>
    </tr>
    <tr>
      <td>GTA 5</td>
      <td>30.34秒</td>
      <td>41.58秒</td>
      <td>51.38秒</td>
      <td>56.47秒</td>
    </tr>
    <tr>
      <td>Resident Evil 2 Remake</td>
      <td>2.37秒</td>
      <td>2.51秒</td>
      <td>2.51秒</td>
      <td>3.06秒</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`各ゲームによりバラつきがあるものの、NVMe SSD > AHCI SSD > SSHD > HDDの順にロード時間が短くなっている。`}</p>
    <p>{`NVMe SSDとAHCI SSDの差はそれほど大きくないが、AHCI SSDとSSHD, HDDとの開きはかなり大きい。価格を考えるとコストパフォーマンスでAHCI SSDは優れていると言える。`}</p>
    <h2 {...{
      "id": "HDDかSSDどちらにするか？あるいは組み合わせるか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#HDD%E3%81%8BSSD%E3%81%A9%E3%81%A1%E3%82%89%E3%81%AB%E3%81%99%E3%82%8B%E3%81%8B%EF%BC%9F%E3%81%82%E3%82%8B%E3%81%84%E3%81%AF%E7%B5%84%E3%81%BF%E5%90%88%E3%82%8F%E3%81%9B%E3%82%8B%E3%81%8B%EF%BC%9F",
        "aria-label": "HDDかSSDどちらにするか？あるいは組み合わせるか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDかSSDどちらにするか？あるいは組み合わせるか？`}</h2>
    <div className="b-m-c">
  <Image {...props} name="pattern" alt="SSDとHDDの構成パターン" mdxType="Image" />
    </div>
    <p>{`HDDはSSDと比べると半額程度で購入でき、容量が大きくなるにつれて単価も下がってくる。一方でSSDは容量あたりの値段は高いが高速で故障率も低く、HDDより明らかに優れている。それぞれ一長一短なので、おすすめ例として幾つかケースを考えてみる。`}</p>
    <h3 {...{
      "id": "500GBも使わないノートパソコンユーザーは256GBのSSD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#500GB%E3%82%82%E4%BD%BF%E3%82%8F%E3%81%AA%E3%81%84%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AF256GB%E3%81%AESSD",
        "aria-label": "500GBも使わないノートパソコンユーザーは256GBのSSD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`500GBも使わないノートパソコンユーザーは256GBのSSD`}</h3>
    <p>{`まずノートパソコンを日常的に使うものの、ゲームはせず、動画もGooglePhotoなどにアップロードするので十分だという人は256GBのSSDを積んでおけば問題がない。`}</p>
    <p>{`500GBのHDDと256GBのSSDなら値段はほとんど変わらないが、256GBのSSDをおすすめする。アプリの起動に時間がかからず快適であることと、500GBは持て余す可能性が高いということが理由である。`}</p>
    <p>{`また、万が一容量が足りなくなったとしても外付けのハードディスクを購入すれば済むのであえて500GBのHDDにする必要はない。`}</p>
    <h3 {...{
      "id": "動画も撮り、ゲームもするデスクトップユーザーは512GBSSDと2TB-HDD",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8B%95%E7%94%BB%E3%82%82%E6%92%AE%E3%82%8A%E3%80%81%E3%82%B2%E3%83%BC%E3%83%A0%E3%82%82%E3%81%99%E3%82%8B%E3%83%87%E3%82%B9%E3%82%AF%E3%83%88%E3%83%83%E3%83%97%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%E3%81%AF512GBSSD%E3%81%A82TB-HDD",
        "aria-label": "動画も撮り、ゲームもするデスクトップユーザーは512GBSSDと2TB HDD permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`動画も撮り、ゲームもするデスクトップユーザーは512GBSSDと2TB HDD`}</h3>
    <p>{`次にパソコンのヘビーユーザーで動画も高画質なものを撮りため、ゲームもよく遊ぶ場合はSSDとHDDを組み合わせると良い。この場合、動画はHDD、ゲームはSSDへ保存する。`}</p>
    <p>{`ゲームの起動時間はOSの起動時間と同様でSSDがHDDの2倍近くとなり、SSDへインストールすることでロード中の待ち時間が少なくなり、快適にプレイできる。`}</p>
    <h3 {...{
      "id": "HDDとSSDの組み合わせが面倒な人はOptaneメモリーはおすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#HDD%E3%81%A8SSD%E3%81%AE%E7%B5%84%E3%81%BF%E5%90%88%E3%82%8F%E3%81%9B%E3%81%8C%E9%9D%A2%E5%80%92%E3%81%AA%E4%BA%BA%E3%81%AFOptane%E3%83%A1%E3%83%A2%E3%83%AA%E3%83%BC%E3%81%AF%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "HDDとSSDの組み合わせが面倒な人はOptaneメモリーはおすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDDとSSDの組み合わせが面倒な人はOptaneメモリーはおすすめ`}</h3>
    <div className="b-m-c">
      <Image {...props} name="optane_desc" alt="SSDとHDDの構成パターン" mdxType="Image" />
    </div>
    <p>{`SSDとHDDを組み合わせて使用する場合、大きめの動画ファイルはHDDへ、良く使うアプリはSSDへといった具合にファイルごとに保存先を変えるという手間が発生する。`}</p>
    <p><strong parentName="p">{`大容量でかつ高速化を実現したいが、ファイルの置き場所を考える手間は省きたい`}</strong>{`場合にOptaneメモリーを導入すると効果的である。`}</p>
    <p><a parentName="p" {...{
        "href": "https://amzn.to/2YvFdcO",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`Optaneメモリー`}</a>{`はメモリーと高速にアクセスできるインテル製のストレージでHDDやSSDと組み合わせて使用する。`}</p>
    <p>{`16GBや32GBと少量のストレージであるが、頻繁にアクセスされるファイルをキャッシュとして持つことで、読み出し要求が来た時にHDD/SSDを経由せずにデータを返すことができる。`}</p>
    <p>{`頻繁なアクセスが発生するファイルはOptaneが自動で学習して決めてくれるため、極めて高いキャッシュヒット率を誇り、`}<strong parentName="p">{`結果としてHDDをSSDと同等に高速化`}</strong>{`することができる。`}</p>
    <p>{`ただ、予想できると思うが、昔ハマっていたゲームを久しぶりにプレイするような場合は、確実にキャッシュにヒットしないためHDDと同等の速度まで落ちてしまう。`}</p>
    <p>{`OptaneはSSDと比較しても高速であるため、体感的なメリットは感じにくい可能性もあるが、組み合わせて使用することもできる。`}</p>
    <h2 {...{
      "id": "HDDSSDの選び方まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#HDDSSD%E3%81%AE%E9%81%B8%E3%81%B3%E6%96%B9%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "HDDSSDの選び方まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HDD/SSDの選び方まとめ`}</h2>
    <p>{`HDD/SSDの選び方を説明した。`}</p>
    <p>{`まず、クラウドサービスや外付け機器の利用も念頭にいれつつ容量を決定する。容量が決まれば価格を考慮に入れながらストレージの構成を決定する。`}</p>
    <p>{`以上の２点をきっちり抑えておけばストレージを難なく選べるはずである。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      